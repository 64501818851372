export default {
  props:{
    icon :{
      name :'icon',
      type : String,
      default : null
     },
     color:{
      name :'color',
      type : String,
      default : "#000000"
     },
     stat:{
      name :'stat',
      type : Number,
      default : 0
     },
     label:{
      name :'label',
      type : String,
      default : " "
     }
    },

  data: () => ({
      
  }),
  computed:{
    metricScanNbStyle: function(){
      return {
        color:this.color
      }
    }
  }
}