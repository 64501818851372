<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
  
          <metric-block v-for="metric in metrics" 
          :key="metric.label" 
          :label="metric.label" 
          :icon="metric.icon" 
          :color="metric.color"
          :stat="metric.stat"
          />

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import AmigoResource from '@/resources/amigo.js'
  import DigitalMarketingResource from '@/resources/digital-marketing.js'
  import MetricBlock from '@/components/MetricBlock/MetricBlock.vue'

  export default {
    name: 'Home',
    
    components:{
      MetricBlock
    },
    data () {
      return {
          metrics : [
          {
          name : "qrCode",
          icon : "qrcode",
          color: "#46a6a6",
          stat : 0,
          label : "QR Codes créés"
          },
          {
          name : "broadcast",
          icon : 'paper-plane',
          color: "#c7374d",
          stat : 0,
          label : "Posts publiés"
          },
          {
          name : "user",
          icon : 'users',
          color: "#3E3E3E",
          stat : 0,
          label : "Total abonnés"
          },
          {
          name : "scan-amigo",
          icon : 'mobile-alt',
          color: "#f4742e",
          stat : 0,
          label : "Scans dans l'App"
          },
          {
          name : "scan-other",
          icon : 'qrcode',
          color: "#8e807a",
          stat : 0,
          label : "Scans hors App"
          }
        ]
      }
    },
    created: function () {

      AmigoResource.getSubscriptions().then(
        (response)=>{
          let userMetric = this.metrics.find(element => element.name == "user");
          let scanAmigoMetric = this.metrics.find(element => element.name == "scan-amigo");
          let scanOtherMetric = this.metrics.find(element => element.name == "scan-other");

          if(response.data.subscriptions)
          {
            let subscriptions = response.data.subscriptions;
            subscriptions.forEach(elt => {
              if(elt.users)
              {
                userMetric.stat += elt.users.total;
              }
              if(elt.scans.client)
              {
                scanAmigoMetric.stat += elt.scans.client.amigo; 
              }
              if(elt.scans.client)
              {
                scanOtherMetric.stat += elt.scans.client.other; 
              }
            });
          }
        }).catch(
        (error) => {
          console.log(error)
        });
      DigitalMarketingResource.getMetrics().then(
        (response)=>{
          let qrCodeMetric = this.metrics.find(element => element.name == "qrCode");
          let broadcastMetric = this.metrics.find(element => element.name == "broadcast");
          
          qrCodeMetric.stat = response.data.qrcodes.count;
          broadcastMetric.stat = response.data.broadcasts.count;

        }).catch(
        (error) => {
          console.log(error)
        })
    },
  }
  
</script>