import axios from 'axios'
import getEnv from '@/utils/env'

export default {
  getMetrics: function () {
    return new Promise(
      function (resolve, reject) {
        axios.get(getEnv('VUE_APP_DM_ENDPOINT')+'/wp-json/wp/v2/metrics',
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Basic '+getEnv('VUE_APP_DM_TOKEN')
            }
          }
        ).then(function (response) {
          return resolve(response)
        })
          .catch(function (error) {
            return reject(error)
          })
      }
    )
  }
}
