import axios from 'axios'
import getEnv from '@/utils/env'

export default {
  getSubscriptions: function () {
    return new Promise(
      function (resolve, reject) {
        axios.get(getEnv('VUE_APP_AMIGO_ENDPOINT')+'/provider/'+getEnv('VUE_APP_DM_CODE')+'/subscriptions',
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': getEnv('VUE_APP_AMIGO_TOKEN')
            }
          }
        ).then(function (response) {
          return resolve(response)
        })
          .catch(function (error) {
            return reject(error)
          })
      }
    )
  }
}
